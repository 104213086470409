import React from 'react'
import GoogleAuth from './components/GoogleAuth';

function App() {
  return(
    <GoogleAuth />
    )
}

export default App;
