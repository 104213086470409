import React from 'react';

import Header from './header';
import VideoPlayer from './VideoPlayer';

class GoogleAuth extends React.Component{
    state = {
        isSignedIn: null
    }
    componentDidMount() {
        window.gapi.load('client:auth2', () => {
            window.gapi.client.init({
                clientId: '585369336436-brr9dlap65h0k5t462513bff8ckutc7g.apps.googleusercontent.com',
                scope: 'email'
            }).then(() => {
                this.auth = window.gapi.auth2.getAuthInstance()
                this.setState({ isSignedIn: this.auth.isSignedIn.get() })
                this.auth.isSignedIn.listen(this.onAuthChange)
            })
        })
    }
    onAuthChange = () => {
        this.setState({ isSignedIn: this.auth.isSignedIn.get() })
    }
    onSignOutClick = () => {
        this.auth.signOut()
    }
    onSignInClick = () => {
        this.auth.signIn()
    }
    renderAuthButton() {
        if (this.state.isSignedIn === null) {
            return null
        } else if (this.state.isSignedIn) {
            return (
                <div>
                    <Header onSignOutClick={ this.onSignOutClick } />
                    <VideoPlayer />
                </div>
            )
        } else {
            return (
                <div className='d-flex justify-center'>
                    <button onClick={ this.onSignInClick } className='ui red google button'>
                        <i className='google icon' />
                        Sign In with Google
                    </button>
                </div>
            )
        }
    }
    render() {
        return <div>{ this.renderAuthButton() }</div>
    }
}

export default GoogleAuth
