import React from 'react';

const VideoList = ({videoList, onSelectVideo}) => {
    let _videoIndex = 0
    const selectVideo = (video) => {
        onSelectVideo(video)
    }
    const list = videoList.map(video => {
        _videoIndex++;
        return (
            <div className='d-flex row video-item' key={ _videoIndex } onClick={() => selectVideo(video) }>                
                <span className='video-index'>{ _videoIndex }.</span>
                <span className='video-title'>{ video.file }</span>
            </div>
        )
    })
    return (
        <div className='video-list'>
            { list }
        </div>
    )
}

export default VideoList