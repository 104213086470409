import React from 'react';

import VideoList from './VideoList';
import Player from './Player';

class VideoPlayer extends React.Component {
    state = {
        listLoaded: false,
        selectedVideo: null,
        videoList: []
    }
    componentDidMount() {
        fetch('https://video-repository-324514.nw.r.appspot.com/videos')
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        listLoaded: true,
                        videoList: result
                    });
                },
                (error) => {
                    console.log(error)
                }
            )
    }
    selectVideo = (video) => {
        this.setState({ selectedVideo: video });
        fetch('https://video-repository-324514.nw.r.appspot.com/url?fileName=MMD/'+video.origin+'/'+video.file)
            .then(res => res.text())
            .then(
                (response) => {
                    this.setState({ 
                        selectedVideo: { 
                            origin: video.origin, 
                            file: video.file, 
                            url: response 
                        } 
                    });
                }
            );
    }
    render() {
        return (
            this.state.listLoaded ? (                
            <div className='d-flex'>
                <VideoList videoList={ this.state.videoList } onSelectVideo={ this.selectVideo } />
                <Player video={ this.state.selectedVideo } />
            </div>
            ) : null
        )
    }
}

export default VideoPlayer