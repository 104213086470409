import React from "react";

const Player = ({ video }) => {
    return (
        video ? (
        <div className="d-flex column player">
            <video controls src={ video.url }></video>
            <span className="video-title">{ video.file }</span>
        </div>
        ) : null
    )
}

export default Player