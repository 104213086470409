import React from 'react';

const Header = (props) => {
    function onSignOutClick() {
        props.onSignOutClick();
    }
    return (
      <div className='d-flex header justify-between'>
        <span> My Videos </span>
        <button onClick={ onSignOutClick } className='ui red google button'>
            <i className='google icon' />
            Sign Out
        </button>
      </div>  
    )
}

export default Header